.cd-timeline__container {
  --cd-color-1: hsl(206, 21%, 24%);
  --cd-color-1-h: 206;
  --cd-color-1-s: 21%;
  --cd-color-1-l: 24%;
  --cd-color-2: hsl(205, 38%, 89%);
  --cd-color-2-h: 205;
  --cd-color-2-s: 38%;
  --cd-color-2-l: 89%;
  --cd-color-3: hsl(207, 10%, 55%);
  --cd-color-3-h: 207;
  --cd-color-3-s: 10%;
  --cd-color-3-l: 55%;
  --cd-color-4: hsl(111, 51%, 60%);
  --cd-color-4-h: 111;
  --cd-color-4-s: 51%;
  --cd-color-4-l: 60%;
  --cd-color-5: hsl(356, 53%, 49%);
  --cd-color-5-h: 356;
  --cd-color-5-s: 53%;
  --cd-color-5-l: 49%;
  --cd-color-6: hsl(47, 85%, 61%);
  --cd-color-6-h: 47;
  --cd-color-6-s: 85%;
  --cd-color-6-l: 61%;
  --cd-header-height: 200px;
  --font-primary: 'Droid Serif', serif;
  --font-secondary: 'Open Sans', sans-serif
}

@media (min-width: 64rem) {
  :root {
    --cd-header-height: 300px
  }
}

.cd-main-header {
  height: 200px;
  height: var(--cd-header-height);
  background: hsl(206, 21%, 24%);
  background: var(--cd-color-1);
  color: hsl(0, 0%, 100%);
  color: var(--color-white);
  -moz-osx-font-smoothing: grayscale
}

.cd-main-header h1 {
  color: inherit
}

.cd-timeline {
  overflow: hidden;
  padding: 2em 0;
  padding: var(--space-lg) 0;
  color: hsl(207, 10%, 55%);
  color: var(--cd-color-3);
  background-color: hsl(205, 38%, 93.45%);
  background-color: hsl(var(--cd-color-2-h), var(--cd-color-2-s), calc(var(--cd-color-2-l) * 1.05));
}

.cd-timeline h2 {
  font-weight: 700
}

.cd-timeline__container {
  position: relative;
  padding: 1.25em 0;
  padding: var(--space-md) 0
}

.cd-timeline__container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: hsl(205, 38%, 89%);
  background: var(--cd-color-2)
}

@media (min-width: 64rem) {
  .cd-timeline__container::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}

.cd-timeline__block {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: 2em;
  margin-bottom: var(--space-lg)
}

.cd-timeline__block:last-child {
  margin-bottom: 0
}

@media (min-width: 64rem) {
  .cd-timeline__block:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }
}

.cd-timeline__img {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px hsl(0, 0%, 100%), inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 4px var(--color-white), inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)
}

.cd-timeline__img img {
  width: 24px;
  height: 24px
}

@media (min-width: 64rem) {
  .cd-timeline__img {
    width: 60px;
    height: 60px;
    -ms-flex-order: 1;
    order: 1;
    margin-left: calc(5% - 30px);
    will-change: transform
  }

  .cd-timeline__block:nth-child(even) .cd-timeline__img {
    margin-right: calc(5% - 30px)
  }
}

.cd-timeline__img--picture {
  background-color: hsl(111, 51%, 60%);
  background-color: var(--cyan)
}

.cd-timeline__img--movie {
  background-color: hsl(356, 53%, 49%);
  background-color: var(--cd-color-5)
}

.cd-timeline__img--location {
  background-color: hsl(47, 85%, 61%);
  background-color: var(--cd-color-6)
}

.cd-timeline__content {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  margin-left: 1.25em;
  margin-left: var(--space-md);
  background: hsl(0, 0%, 100%);
  background: var(--color-white);
  border-radius: 0.25em;
  border-radius: var(--radius-md);
  padding: 1.25em;
  padding: var(--space-md);
  box-shadow: 0 3px 0 hsl(205, 38%, 89%);
  box-shadow: 0 3px 0 var(--cd-color-2)
}

.cd-timeline__content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-right-color: hsl(0, 0%, 100%);
  border-right-color: var(--color-white)
}

.cd-timeline__content h2 {
  color: #49138c;
}

@media (min-width: 64rem) {
  .cd-timeline__content {
    width: 45%;
    -ms-flex-positive: 0;
    flex-grow: 0;
    will-change: transform;
    margin: 0;
    font-size: 0.8em;
    --line-height-multiplier: 1.2
  }

  .cd-timeline__content::before {
    top: 24px
  }

  .cd-timeline__block:nth-child(odd) .cd-timeline__content::before {
    right: auto;
    left: 100%;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-left-color: hsl(0, 0%, 100%);
    border-left-color: var(--color-white)
  }
}

.cd-timeline__date {
  color: white;
  font-size: 2em;
}

@media (min-width: 64rem) {
  .cd-timeline__date {
    position: absolute;
    width: 100%;
    left: 120%;
    top: 20px
  }

  .cd-timeline__block:nth-child(even) .cd-timeline__date {
    left: auto;
    right: 120%;
    text-align: right
  }
}

@media (min-width: 64rem) {
  .cd-timeline__img--hidden, .cd-timeline__content--hidden {
    visibility: hidden
  }

  .cd-timeline__img--bounce-in {
    -webkit-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s
  }

  .cd-timeline__content--bounce-in {
    -webkit-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s
  }

  .cd-timeline__block:nth-child(even) .cd-timeline__content--bounce-in {
    -webkit-animation-name: cd-bounce-2-inverse;
    animation-name: cd-bounce-2-inverse
  }
}

/* Custom */
.cd-timeline__content {
  background-color: white;
  padding: 2em;
}

.cd-timeline__content .data-content {
  word-break: break-all;
}

.cd-timeline__block .event-icon {
  font-size: 2.5em;
}
