html {
  font-size: 10px;
}

code {
  font-family: 'Roboto Mono', 'Courier New', monospace;
}

body {
  --container: auto;
  --cyan: #4fd3d3;

  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  transition: all .3s;
  text-decoration: none;
}

a {
  color: #007bff;

}

a:hover {
  color: #0056b3;
}

@screen md {
  body {
    --container: 720px;
  }
}

@screen lg {
  body {
    --container: 960px;
  }
}

@screen xl {
  body {
    --container: 1140px;
  }
}
